.colorPicker {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(136, 145, 170) 0px 0px 0px 1px;
  border-radius: 4px;
  transition: box-shadow 100ms ease-in 0s;
  cursor: pointer;
  padding: 0px 8px;
  height: 36px;
}
